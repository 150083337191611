.light .item-form .input .p-multiselect-panel {
    background: #fff;
    border-color: #fff;
}

.light .item-form .input .p-multiselect-panel .p-multiselect-items-wrapper::-webkit-scrollbar {
    width: 4px;
    height: 6px;
}

.light .item-form .input .p-multiselect-panel .p-multiselect-items-wrapper::-webkit-scrollbar-thumb {
    background-color: #bebebe;
    border-radius: 10px;
}

.light .item-form .input .p-multiselect-panel .p-multiselect-items-wrapper::-webkit-scrollbar-thumb:hover {
    background-color: #cbcbcb;
}

.light .item-form .input .p-multiselect-panel .p-multiselect-items-wrapper::-webkit-scrollbar-thumb:active {
    background-color: #cbcbcb;
}

.light .item-form .input .p-multiselect-panel .p-multiselect-header {
    background: #efefef;
}

.light .item-form .input .p-multiselect-panel .p-multiselect-header .p-multiselect-filter-container input {
    background: #ffffff;
    border-color: #bebebe;
}

.light .item-form .input .p-multiselect-panel .p-multiselect-header .p-multiselect-filter-container input svg {
    color: #adadad;
}

.light .item-form .input .p-multiselect-panel .p-multiselect-header .p-checkbox.p-component .p-checkbox-box {
    border-color: #919191;
    background: #f5f5f5;
}

.item-form .input .p-multiselect-panel .p-multiselect-items-wrapper .p-multiselect-items {
    padding: 0;
}

.light .item-form .input .p-multiselect-panel .p-multiselect-header .p-multiselect-close.p-link {
    color: black;
}

.light .item-form .input .p-multiselect-panel .p-multiselect-items-wrapper .p-multiselect-items .p-multiselect-item .p-checkbox-box {
    background: #f5f5f5;
    border-color: #c3c3c3;
}

.light .item-form .input .p-multiselect-panel .p-multiselect-items-wrapper .p-multiselect-items .p-multiselect-item span {
    color: #000000;
}

.light .item-form .input .p-multiselect-panel .p-multiselect-footer {
    color: #000;
    background: #efefef;
}

.light .item-form .input .p-multiselect-label-container .p-multiselect-token {
    background: #43a5f4;
}

.light .item-form .input .p-multiselect-label-container .p-multiselect-label.p-placeholder {
    color: #8e8e8e;
}


.dark .item-form .input .p-multiselect-panel {
    background: #47618b;
}

.dark .item-form .input .p-multiselect-panel .p-multiselect-header {
    background: #364b6c;
}

.dark .item-form .input .p-multiselect-panel .p-multiselect-footer {
    background: #364b6c;
}

.dark .item-form .input .p-multiselect-panel .p-multiselect-header .p-checkbox.p-component .p-checkbox-box {
    background: #88afff;
    border-color: #bcd0fa;
}

.dark .item-form .input .p-multiselect-panel .p-multiselect-items-wrapper .p-multiselect-items .p-multiselect-item .p-checkbox-box {
    background: #bcd0fa;
    border-color: #6889ce;
}

.dark .item-form .input .p-multiselect-panel .p-multiselect-header .p-multiselect-filter-container input {
    background: #5973a6;
}
