/** Start **/
.menu-main-top .menu-top {
    position: relative;
    border-right: 1px solid #787878;
    display: inline-block;
    vertical-align: middle;
}

.menu-main-top .menu-top .menu-top-logo {
    width: 180px;
    text-align: center;
    padding: 0 5px;
    display: inline-block;
    vertical-align: middle;
}

.menu-main-top .menu-top .menu-top-logo span {
    font-weight: bold;
    font-size: 18px;
}

.menu-main-top .menu-top .menu-top-logo span:first-child {
    font-size: 24px;
    color: #c68a26;
}

.menu-main-top .menu-top .menu-top-logo div {
    display: inline;
    border-bottom: 2px dashed #1f2d40;
    transition: all .3s;
    cursor: pointer;
}

.menu-main-top .menu-top .menu-top-logo div:hover {
    border-bottom: 2px dashed #c68a26;
    transition: all .3s;
    color: #c68a26;
}

.menu-main-top .menu-top button {
    display: inline-block;
    vertical-align: middle;
    color: white;
}

.menu-main-top .search {
    display: inline-block;
    vertical-align: middle;
    padding-right: 10px;
}

.menu-main-top .search input {
    margin-left: 10px;
    width: 280px;
}

.light .menu-main-top .search input {
    background: #ffffff;
    border-color: #c6c6c6;
}

.menu-main-top .language {
    display: inline-block;
    vertical-align: middle;
}

.light .menu-main-top .language .language-dropdown {
    background: #fff;
    border-color: #c6c6c6;
}

.light .menu-main-top .language .language-dropdown .p-dropdown-label {
    color: #000;
}

.light .menu-main-top .language .language-dropdown .p-dropdown-trigger svg {
    color: #000;
}

.light .menu-main-top .language .language-dropdown .language-dropdown-open-content {
    background: #eae9e9;
    border-color: #ffffff;
}

.menu-main-top .language .language-dropdown .language-dropdown-open-content .p-dropdown-items {
    padding: 0;
}

.light .menu-main-top .language .language-dropdown .language-dropdown-open-content .p-dropdown-item {
    color: #000;
    transition: all .5s;
}

.light .menu-main-top .language .language-dropdown .language-dropdown-open-content .p-dropdown-item:hover {
    color: #4b6880;
    background: #dbdbdb;
    transition: all .5s;
}

/** end **/
.user-top-panel .item {
    display: inline-block;
    vertical-align: middle;
    padding: 0 5px;
}

.user-top-panel .item button {
    box-shadow: 0 2px 2px 0 rgb(0 0 0 / 14%), 0 3px 1px -2px rgb(0 0 0 / 20%), 0 1px 5px 0 rgb(0 0 0 / 12%);
}

.user-top-panel .item.item-user button {
    padding: 0;
}

.user-top-panel .item.item-bell button {
    background: #c68a26;
    border: 1px solid #b9b9b9;
    color: white;
}

.user-top-panel .item.item-message button {
    color: white;
}

.user-top-panel .item-mode svg.icon-mode {
    margin-top: 7px;
    width: 25px;
    height: 25px;
}

.user-top-panel .item-mode svg.icon-mode.light {
    color: #828282;
}

.user-top-panel .item-mode svg.icon-mode.dark {
    color: #d1ca92;
}

.user-top-panel .item-mode .mode-btn {
    border-radius: 50%;
    width: 40px;
    height: 40px;
    text-align: center;
    cursor: pointer;
    transition: all .5s;
}

.light .user-top-panel .item-mode .mode-btn {
    background: #d6d6d6;
}

.dark .user-top-panel .item-mode .mode-btn {
    background: #28344a;
}

.light .user-top-panel .item-mode .mode-btn:hover {
    background: #b4b4b4;
    transition: all .5s;
}

.light .user-top-panel .item-mode .mode-btn:hover svg.icon-mode {
    color: #fff;
    transition: all .5s;
}

.dark .user-top-panel .item-mode .mode-btn:hover {
    background: #313f59;
    transition: all .5s;
}

.dark .user-top-panel .item-mode .mode-btn:hover svg.icon-mode {
    color: #c4b170;
    transition: all .5s;
}

.light .user-top-panel .overlay-panel-user-menu-top .p-overlaypanel-content {
    padding: 0!important;
}

.light .user-top-panel .overlay-panel-user-menu-top .items .item-link {
    padding: 10px 20px;
    transition: all .5s;
    cursor: pointer;
    color: #000;
}

.light .user-top-panel .overlay-panel-user-menu-top .items .item-link:hover {
    background: #ffffff;
    transition: all .5s;
    color: #00b297;
}

.light .user-top-panel .overlay-panel-user-menu-top .items .item-link i {
    margin-right: 10px;
}

.dark .user-top-panel .overlay-panel-user-menu-top .p-overlaypanel-content {
    padding: 0!important;
}

.dark .user-top-panel .overlay-panel-user-menu-top .items .item-link {
    padding: 10px 20px;
    transition: all .5s;
    cursor: pointer;
}

.dark .user-top-panel .overlay-panel-user-menu-top .items .item-link:hover {
    background: #1a2432;
    transition: all .5s;
    color: #00b297;
}

.dark .user-top-panel .overlay-panel-user-menu-top .items .item-link i {
    margin-right: 10px;
}

