@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  /*background: #25344c;*/
  padding: 0;
  margin: 0;
}

header {
  position: fixed;
  width: 100%;
  z-index: 2;
}

header.header-top .card .p-menubar {
  padding: 0.6rem;
}

.light header.header-top .card .p-menubar {
  padding: 0.6rem;
  background: #eae9e9;
  border-color: #ebebeb;
}

.main .content {
  position: fixed;
  width: 100%;
  height: 100%;
  overflow-x: scroll;
  padding: 35px 115px 35px 35px;
}

.main .content.content-open {
  padding-right: 281px;
}

::-webkit-scrollbar-button {
  background-image  : url('');
  background-repeat : no-repeat;
  width             : 5px;
  height            : 0px;
}

::-webkit-scrollbar-thumb {
  -webkit-border-radius : 20px;
  border-radius         : 20px;
  background-color      : #1D2939;
}

::-webkit-scrollbar-thumb:hover {
  background-color : #56999F;
}

::-webkit-resizer {
  background-image  : url('');
  background-repeat : no-repeat;
  width             : 4px;
  height            : 0px;
}

::-webkit-scrollbar {
  width  : 3px;
  height : 2px;
}

.section-page {
  padding: 15px;
}

.fieldset-data {
  padding-top: 35px;
}

.content-page {
  background: #2f4159;
  box-shadow: 0 2px 2px 0 rgb(0 0 0 / 14%), 0 3px 1px -2px rgb(0 0 0 / 20%), 0 1px 5px 0 rgb(0 0 0 / 12%);
  padding-bottom: 35px;
}

.light .content-page {
  background: #fff;
}

.section-content {
  margin-top: 50px;
}

.fieldset {
  box-shadow: 0 2px 2px 0 rgb(0 0 0 / 14%), 0 3px 1px -2px rgb(0 0 0 / 20%), 0 1px 5px 0 rgb(0 0 0 / 12%);
}

.dark .fieldset {
  border: 1px solid #40628f;
}

.light .fieldset {
  border: 1px solid #83bbf2;
  background: none;
  box-shadow: none;
}

.fieldset .p-fieldset-legend {
  margin-left: 30px;
  background: #40628f;
}

.light .fieldset .p-fieldset-legend {
  background: #64B5F6;
  border: 1px solid #83bbf2;
}

.light .fieldset .p-fieldset-legend a:hover {
  background: #78b3f0;
}

body .light .content {
  background: #f7f7f7;
}

body .dark .content {
  background: #25344c;
}

.light .overlay-panel:before {
  border-bottom-color: #fff;
}

.light .overlay-panel:after {
  border-bottom-color: #fff;
}

.light .overlay-panel {
  background: #eae9e9;
  border-color: #fff;
}

.toolbar-panel button {
  color: #fff;
}

.toolbar-panel button:hover .p-button-label {
  color: #fff;
}

.toolbar-panel button svg {
  color: #fff;
}

.toolbar-panel .panel-content-right button:first-child {
  margin-right: 10px;
}

.toolbar-panel .panel-content-left button svg {
  margin-right: 5px;
}

.light .toolbar-panel {
  background: #eae9e9;
  border-color: #ebebeb;
}

body .p-button.p-button-loading-label-only .p-button-loading-icon {
  margin-right: 5px;
}

body .toast .p-toast-message.p-toast-message-info {
  border-width: 0;
  border-radius: 20px;
}

body .dark .toast .p-toast-message.p-toast-message-info {
  background: #84a3df;
}

body .light .toast .p-toast-message.p-toast-message-info {
  background: #84a3df;
}

body .toast .p-toast-message .p-toast-message-content {
  border-width: 0;
  padding: 5px;
}

