table tr .control a {
    padding: 0 10px;
}

table tr .control a button.btn-edit {
    color: #fff;
    background: #b17b22;
    border-color: #ff9f00;
    transition: all .3s;
}

table tr .control a button.btn-edit svg {
   transform: rotate(0deg);
    transition: all .3s;
}

table tr .control a button.btn-edit:hover {
    background: #e0a543;
    border-color: #ff9f00;
    color: #fff;
    transition: all .3s;
}

table tr .control a button.btn-edit:hover svg {
    transform: rotate(360deg);
    transition: all .3s;
}

table tr .control button.btn-delete {
    color: #fff;
    background: #a96363;
    border-color: #ff5454;
    transition: all .3s;
}

table tr .control button.btn-delete svg {
    transform: rotate(0deg);
    transition: all .3s;
}

table tr .control button.btn-delete:hover {
    background: #c96262;
    border-color: #ff5454;
    color: #fff;
    transition: all .3s;
}

table tr .control button.btn-delete:hover svg {
    transform: rotate(360deg);
    transition: all .3s;
}
