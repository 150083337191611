.item-form .dropdown-content {
    position: relative;
}

.item-form .dropdown-content .loading-mask {
    position: absolute;
    right: 0;
    z-index: 1;
    width: 100%;
    height: 100%;
}

.item-form .dropdown-content .loading-mask .loading-icon {
    right: 0;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
}

.dropdown.loading .p-dropdown-trigger svg {
    display: none;
}

.dropdown .p-dropdown-footer .dropdown-new-element {
    width: 100%;
}

.dropdown .p-dropdown-footer .dropdown-new-element button {
    width: 100%;
}

.dropdown.p-dropdown {
    height: 43px;
    background: #47618b;
}

.dropdown .dropdown-panel {
    border-radius: 10px;
}

.dropdown .dropdown-panel .p-dropdown-items-wrapper {
    background: #4c6088;
    border-radius: 10px;
}

.dropdown .dropdown-panel .p-dropdown-items-wrapper .p-dropdown-items {
    padding: 0;
}

.dropdown .dropdown-panel .p-dropdown-items-wrapper .p-dropdown-items .p-dropdown-item {
    transition: all .4s;
}

.dropdown .dropdown-panel .p-dropdown-items-wrapper .p-dropdown-items .p-dropdown-item:hover {
    background: #697cad;
    transition: all .4s;
}

.dropdown .dropdown-panel .p-dropdown-items-wrapper .p-dropdown-items .p-dropdown-item.p-highlight {
    background: #697cad;
}

.dropdown .dropdown-panel .p-dropdown-items-wrapper .p-dropdown-empty-message .dropdown-empty-message-content {
    position: relative;
    min-height: 300px;
}

.dropdown .dropdown-panel .p-dropdown-items-wrapper .p-dropdown-empty-message .dropdown-empty-message-content .icon {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
}

.dropdown .dropdown-panel .p-dropdown-items-wrapper .p-dropdown-empty-message .dropdown-empty-message-content .icon span {
    display: block;
    text-align: center;
    color: #86a7e1;
    font-weight: bold;
}

.dropdown .dropdown-loading {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
}

.dropdown .dropdown-loading .loader {
    width: 48px;
    height: 48px;
    border-radius: 50%;
    display: inline-block;
    border-top: 3px solid #8499ce;
    border-right: 3px solid transparent;
    box-sizing: border-box;
    animation: rotation 1s linear infinite;
}

@keyframes rotation {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

.dropdown-content .dropdown .p-dropdown-panel .form-search {
    margin-bottom: 15px;
}
