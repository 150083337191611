.breadcrumb {
    padding: 15px 20px;
    margin-bottom: 35px;
    box-shadow: 0 2px 2px 0 rgb(0 0 0 / 14%), 0 3px 1px -2px rgb(0 0 0 / 20%), 0 1px 5px 0 rgb(0 0 0 / 12%);
    border-radius: 10px;
}

.light .breadcrumb {
    background: #eae9e9;
}

.dark .breadcrumb {
    background: #2f4159;
}

.light .breadcrumb .breadcrumb-item {
    color: #6e6e6e;
}

.breadcrumb .breadcrumb-item {
    display: inline-block;
    vertical-align: middle;
    padding: 5px;
    transition: all .5s;
    cursor: pointer;
}

.light .breadcrumb .breadcrumb-item:hover {
    background: #dedede;
}

.breadcrumb .breadcrumb-item:hover {
    color: #94c4ef;
    transition: all .5s;
    background: #324968;
    border-radius: 10px;
}

.breadcrumb .breadcrumb-item.active:hover {
    background: none;
    color: #94c4ef;
}

.breadcrumb .breadcrumb-item.disable:hover {
    background: none;
    color: #718aab;
}

.breadcrumb .breadcrumb-item.active {
    color: #4da9fa;
}

.breadcrumb .breadcrumb-item .breadcrumb-next {
    display: inline-block;
    vertical-align: middle;
}

.breadcrumb .breadcrumb-item .breadcrumb-next .icon {
    margin-left: 5px;
    margin-right: 0;
}

.breadcrumb .breadcrumb-item svg {
    margin-right: 5px;
}

.breadcrumb .breadcrumb-item a {
    margin-right: 5px;
    text-decoration: none;
}

.breadcrumb .breadcrumb-item span {
    margin-right: 5px;
}
