.section-page-list .translation-fieldset {
    margin-bottom: 25px;
}

.section-page-list .translation-fieldset .translation-fieldset-legend {
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.section-page-list .translation-fieldset .translation-fieldset-legend img {
    width: 30px;
    height: auto;
    margin-right: 10px;
}

.section-page-list .translation-fieldset .translations .translation-item .translation-content {
    display: flex;
    justify-content: flex-start;
    align-items: flex-end;
}

.section-page-list .translation-fieldset .translations .translation-item .translation-content .item-form {
    margin-top: 0;
}

.section-page-list .translation-fieldset .translations .translation-item .translation-content img {
    width: auto;
    height: 40px;
}

.section-page-list .translation-fieldset .translations .translation-item .splitter {
    width: 100%;
}

.section-page-list .translation-fieldset .translations .translation-item .splitter .editor {
    width: 100%;
}

.section-page-list .translation-fieldset .translations .translation-item .splitter .result {
    padding: 10px;
}
