#sign .sign-main {
	position   : absolute;
	width      : 320px;
	/*height     : 400px;*/
	left       : 50%;
	top: 50%;
	transform  : translate(-50%, -50%);
	box-shadow : 0 2px 2px 0 rgb(0 0 0 / 14%), 0 3px 1px -2px rgb(0 0 0 / 20%), 0 1px 5px 0 rgb(0 0 0 / 12%);
	border-radius: 15px;
	background: rgb(36 85 137 / 60%);
	border: 1px solid rgb(36 85 137 / 60%);
}

#sign .sign-main .form-sign {
	padding: 25px;
	height: 100%;
	position: relative;
	border: 3px;
}

#sign .sign-main .form-sign .message-error {
	color: #ed6e6e;
	text-align: center;
	margin-top: 25px;
	background: rgba(237, 110, 110, .2);
	border-radius: 15px;
}

#sign .sign-main .form-sign .message-error ul {
	list-style-type: disc;
	margin-left: 20px;
	padding-left: 15px;
}

#sign .sign-main .form-sign .message-error p {
	padding: 5px;
	text-align: left;
}

#sign .sign-main .item {
	width: 100%;
	margin-top: 15px;
}

#sign .sign-main .item label {
	color: #ced1ff;
	padding-bottom: 5px;
	display: block;
}

#sign .sign-main .item input {
	width: 100%;
	background-color: #6167ce;
	border: 1px solid #878be0;
}

#sign .sign-main .item input::placeholder {
	font-size: 12px;
	text-decoration: solid;
	color: #9297ea;
}

#sign .sign-main .item.error small {
	color: #ed6e6e;
}

#sign .sign-main .item.error input {
	border-color: #ed6e6e;
}

#sign .sign-main .item .p-password {
	width: 100%;
}

#sign .sign-main .content-btn-sign {
	display: flex;
	justify-content: center;
	padding: 0.5rem 5rem;
	transition: all .4s;
}

#sign .sign-main .content-btn-sign.error {
	transform: translateY(-50px);
	transition: all .4s;
}

#sign .sign-main .content-btn-sign .btn {
	overflow: initial;
	padding: 10px 50px;
	border-radius: 25px;
	background: none;
	color: #64B5F6;
	margin-top: 25px;
}

#sign .sign-main .tabs {
	display: flex;
	flex-wrap: nowrap;
	justify-content: space-around;
	align-items: center;
}

#sign .sign-main .reg-area-sex {
	padding: 15px 0 10px 0;
}

#sign .sign-main .reg-area-sex small {
	text-align: center;
	display: block;
	margin-bottom: 10px;
}

#sign .sign-main .reg-area-sex .title {
	text-align: center;
	margin-top: 25px;
	margin-bottom: 10px;
	color: #ced1ff;
}

#sign .sign-main .reg-area-sex .area-sex-content {
	display: flex;
	flex-wrap: nowrap;
	justify-content: space-evenly;
}

#sign .sign-main .reg-area-sex .area-sex-content p {
	transition: all .2s;
	color: #ced1ff;
}

#sign .sign-main .reg-area-sex .area-sex-content .icon svg {
	width: 60px;
	transition: all .2s;
}

#sign .sign-main .reg-area-sex .area-sex-content .icon.icon-female {
	border-right: 1px solid #c6c6c6;
	padding-right: 30px;
}

#sign .sign-main .reg-area-sex .area-sex-content .icon.icon-female:hover {
	cursor: pointer;
	color: #ce93d8;
	transition: all .2s;
}

#sign .sign-main .reg-area-sex .area-sex-content .icon.icon-female.active {
	color: #f19dff;
}

#sign .sign-main .reg-area-sex .area-sex-content .icon.icon-male:hover {
	cursor: pointer;
	color: #8dc5f3;
	transition: all .2s;
}

#sign .sign-main .reg-area-sex .area-sex-content .icon.icon-male.active {
	cursor: pointer;
	color: #8dc5f3;
	transition: all .2s;
}

#sign .sign-main .tabs .tab {
	width: 100%;
	background: rgb(28 66 105 / 60%);
	cursor: pointer;
	transition: all .5s;
	box-shadow : 0 2px 2px 0 rgb(0 0 0 / 14%), 0 3px 1px -2px rgb(0 0 0 / 20%), 0 1px 5px 0 rgb(0 0 0 / 12%);
}

#sign .sign-main .tabs .tab.active {
	background: rgb(15 45 76 / 60%);
}

#sign .sign-main .tabs .tab:first-child {
	border-radius: 15px 0 0 0;
}

#sign .sign-main .tabs .tab:last-child {
	border-radius: 0 15px 0 0;
}

#sign .sign-main .tabs .tab:hover {
	background: rgb(15 45 76 / 60%);
	transition: all .5s;
}

#sign .sign-main .tabs .tab span {
	display: block;
	text-align: center;
	padding: 15px;
}

#sign .sign-main .age-items::-webkit-scrollbar {
	width: 12px;
	height: 6px;
}

#sign .sign-main .age-items::-webkit-scrollbar-thumb {
	background-color: #ce93d8;
	border-radius: 10px;
}

#sign .sign-main .age-items::-webkit-scrollbar-thumb:hover {
	background-color: #64b5f6;
}

#sign .sign-main .age-items::-webkit-scrollbar-thumb:active {
	background-color: #64b5f6;
}

#sign .sign-main .item.item-age {
	margin-bottom: 30px;
}

#sign .sign-main .item .input-loading {
	width: 100%;
}

#sign .sign-main .item.item-age small {
	text-align: center;
	display: block;
	margin-bottom: 10px;
	margin-top: 10px;
}

#sign .sign-main .item .dropdown-lang {
	background: #6167ce;
	border: 1px solid #878be0;
}

#sign .sign-main .item .dropdown-lang img {
	width: 30px;
	height: 20px;
	object-fit: cover;
	margin: auto;
}

#sign .sign-main .item .dropdown-lang .p-dropdown-panel .p-dropdown-items {
	padding: 0;
}

#sign .sign-main .item .dropdown-lang .p-dropdown-panel {
	background: #6167ce;
}

#sign .sign-main .age-items {
	overflow-x: auto;
	white-space: nowrap;
	height: 100px;
	transition: all .5s;
	padding-top: 10px;
	width: 100%;
}

#sign .sign-main .title-age {
	text-align: center;
	font-size: 16px;
	color: #ced1ff;
	padding-top: 15px;
}

#sign .sign-main .age-items .age-item {
	display: inline-block;
	vertical-align: middle;
	border-radius: 50%;
	margin: 0 15px;
	border: 1px solid #777777;
	color: white;
	width: 70px;
	height: 70px;
	position: relative;
	transition: all .5s;
	background: none;
	white-space: normal;
}

#sign .sign-main .age-items .age-item:hover {
	animation: pulse 2s infinite;
	background: #ffac48;
	background: linear-gradient(118deg, rgba(255, 172, 72, 1) 0%, rgba(255, 230, 128, 1) 50%, rgba(255, 255, 128, 1) 100%);
}

#sign .sign-main .age-items .age-item:hover div p {
	font-weight: bold;
	font-size: 24px;
	transition: all .1s;
}

#sign .sign-main .age-items .age-item.active {
	animation: pulse 2s infinite;
	background: #ffac48;
	background: linear-gradient(118deg, rgba(255, 172, 72, 1) 0%, rgba(255, 230, 128, 1) 50%, rgba(255, 255, 128, 1) 100%);
}

#sign .sign-main .age-items .age-item div {
	cursor: pointer;
	background: #6f74cf;
	border-radius: 50%;
	z-index: 2;
	position: absolute;
	top: 50%;
	width: 65px;
	height: 65px;
	left: 50%;
	transform: translate(-50%, -50%);
}

#sign .sign-main .age-items .age-item.active div p {
	font-weight: bold;
	font-size: 24px;
}

#sign .sign-main .age-items .age-item div p {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	transition: all .1s;
}

@-webkit-keyframes pulse {
	0% {
		-webkit-box-shadow: 0 0 0 0 rgba(204,169,44, 0.4);
	}
	70% {
		-webkit-box-shadow: 0 0 0 10px rgba(204,169,44, 0);
	}
	100% {
		-webkit-box-shadow: 0 0 0 0 rgba(204,169,44, 0);
	}
}

@keyframes pulse {
	0% {
		-moz-box-shadow: 0 0 0 0 rgba(204,169,44, 0.4);
		box-shadow: 0 0 0 0 rgba(204,169,44, 0.4);
	}
	70% {
		-moz-box-shadow: 0 0 0 10px rgba(204,169,44, 0);
		box-shadow: 0 0 0 10px rgba(204,169,44, 0);
	}
	100% {
		-moz-box-shadow: 0 0 0 0 rgba(204,169,44, 0);
		box-shadow: 0 0 0 0 rgba(204,169,44, 0);
	}
}

.background-area .toast .p-toast-message.p-toast-message-success {
	background: #7c81ed;
	border: solid #9ecd69;
	border-width: 0 0 0 10px;
	color: #fff;
}

.background-area .toast .p-toast-message.p-toast-message-success .p-toast-summary {
	color: #d9ffaf;
}

.background-area .toast .p-toast-message.p-toast-message-success .p-toast-message-content svg.p-toast-message-icon {
	color: #d9ffaf;
}

.background-area .toast .p-toast-message.p-toast-message-error {
	background: #7c81ed;
	border: solid #ef9a9a;
	border-width: 0 0 0 10px;
	color: #fff;
}

.background-area .toast .p-toast-message.p-toast-message-error .p-toast-summary {
	color: #f9a5b1;
}

.background-area .toast .p-toast-message.p-toast-message-error .p-toast-message-content svg.p-toast-message-icon {
	color: #f9a5b1;
}

.background-area .toast .p-toast-message .p-toast-message-content {
	border-width: 0;
}

.background-area .toast .p-toast-message .p-toast-message-content .p-toast-detail {
	color: aliceblue;
	border-top: 1px solid #fff;
	padding-top: 5px;
}

.background-area .toast .p-toast-message .p-toast-message-content svg {
	color: #fff;
}

/* Animation background */

.background-area {
	background: #4e54c8;
	background: -webkit-linear-gradient(to left, #8f94fb, #4e54c8);
	width: 100%;
	height:100%;
	position: fixed;
}

.figures{
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	overflow: hidden;
}

.figures div{
	position: absolute;
	display: block;
	list-style: none;
	width: 20px;
	height: 20px;
	background: rgba(255, 255, 255, 0.2);
	animation: animate 25s linear infinite;
	bottom: -150px;

}

.figures div:nth-child(1){
	left: 25%;
	width: 80px;
	height: 80px;
	animation-delay: 0s;
}


.figures div:nth-child(2){
	left: 10%;
	width: 20px;
	height: 20px;
	animation-delay: 2s;
	animation-duration: 12s;
}

.figures div:nth-child(3){
	left: 70%;
	width: 20px;
	height: 20px;
	animation-delay: 4s;
}

.figures div:nth-child(4){
	left: 40%;
	width: 60px;
	height: 60px;
	animation-delay: 0s;
	animation-duration: 18s;
}

.figures div:nth-child(5){
	left: 65%;
	width: 20px;
	height: 20px;
	animation-delay: 0s;
}

.figures div:nth-child(6){
	left: 75%;
	width: 110px;
	height: 110px;
	animation-delay: 3s;
}

.figures div:nth-child(7){
	left: 35%;
	width: 150px;
	height: 150px;
	animation-delay: 7s;
}

.figures div:nth-child(8){
	left: 50%;
	width: 25px;
	height: 25px;
	animation-delay: 15s;
	animation-duration: 45s;
}

.figures div:nth-child(9){
	left: 20%;
	width: 15px;
	height: 15px;
	animation-delay: 2s;
	animation-duration: 35s;
}

.figures div:nth-child(10){
	left: 85%;
	width: 150px;
	height: 150px;
	animation-delay: 0s;
	animation-duration: 11s;
}

@keyframes animate {

	0%{
		transform: translateY(0) rotate(0deg);
		opacity: 1;
		border-radius: 0;
	}

	100%{
		transform: translateY(-1000px) rotate(720deg);
		opacity: 0;
		border-radius: 50%;
	}

}

/* End animation background */
