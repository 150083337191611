.section-page-list .table tr .color {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    display: inline-block;
    box-shadow: 0 2px 2px 0 rgb(0 0 0 / 14%), 0 3px 1px -2px rgb(0 0 0 / 20%), 0 1px 5px 0 rgb(0 0 0 / 12%);
}

.section-page-list .option-item .option-item-content {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 5px;
}

.section-page-list .option-item .option-item-content .icon img {
    width: 40px;
    height: auto;
    margin-right: 15px;
}

.section-page-list .option-item .option-item-content .name div:first-child {
    font-size: 18px;
}

.section-page-list .option-item .option-item-content .name div:last-child {

}
