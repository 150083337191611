.p-datatable-emptymessage {
    position: relative;
}

.table-empty {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    font-size: 18px;
}

.table-empty span {
    display: block;
    color: #5a7cbc;
}

.table-empty svg {
    width: 150px;
    height: auto;
    color: #5a7cbc;
}
