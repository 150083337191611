.item-form {
    position: relative;
    width: 100%;
    margin-top: 15px;
}

.item-form .error-icon {
    width: 40px;
    display: flex;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: center;
    background: #ef9a9a;
    border-radius: 0 5px 5px 0;
}

.item-form .input.p-password {
    width: 100%;
}

.light .item-form label {
    color: rgba(0, 0, 0, 0.8);
}

.dark .item-form label {
    color: rgba(255, 255, 255, 0.6);
}

.item-form label {
    display: table;
    padding: 5px 5px 8px 0;
    font-size: 14px;
}

.item-form button {
    color: white;
}

.item-form .p-inputgroup input {
    width: 100%;
}

.light .item-form .input {
    background: #f8f8f8;
    border-color: #bebebe;
}

.item-form .p-inputgroup span.p-input-icon {
    width: 100%;
}

.light .item-form .p-inputgroup input {
    background: #f8f8f8;
    border-color: #bebebe;
    color: black;
}

.dark .item-form .p-inputgroup input {
    background: #47618b;
}

.dark .item-form .input {
    background: #47618b;
}

.item-form .input {
    width: 100%;
    height: 42px;
}

.dark .item-form textarea {
    background: #47618b;
}

.light .item-form textarea {
    background: #f8f8f8;
    border-color: #bebebe;
    color: black;
}

.light .item-form .input:hover {
    border-color: #779bdc;
}

.dark .item-form .input:hover {
    border-color: #779bdc;
}

.light .item-form .input:focus {
    border-color: #779bdc;
}

.dark .item-form .input:focus {
    border-color: #779bdc;
}

.item-form .input:focus {
    outline: none;
    box-shadow: none;
}

.item-form.error label {
    color: #ef9a9a;
}

.item-form.error input {
    border-color: #ef9a9a;
}

.item-form.error .textarea {
    border-color: #ef9a9a;
}

.item-form.success label {
    color: #36b9a5;
}

.item-form.success input {
    border-color: #36b9a5;
}

.item-form.success .textarea {
    border-color: #36b9a5;
}

.item-form .textarea {
    width: 100%;
    background: #47618b;
}

.light .item-form .textarea:hover {
    border-color: #779bdc;
}

.dark .item-form .textarea:hover {
    border-color: #779bdc;
}

.item-form .textarea:focus {
    border-color: #779bdc;
    outline: none;
    box-shadow: none;
}

.light .item-form .auto-complete-panel {
    background: #f8f8f8;
}

.dark .item-form .auto-complete-panel {
    background: #4c6088;
}

.item-form .p-progress-spinner .p-progress-spinner-circle {
    stroke: white;
    animation: none;
}
