.light .drawer-left {
    background-color: #eae9e9;
}

.dark .drawer-left {
    background-color: #1D2939;
}

.drawer-left {
    box-shadow: 0 2px 2px 0 rgb(0 0 0 / 14%), 0 3px 1px -2px rgb(0 0 0 / 20%), 0 1px 5px 0 rgb(0 0 0 / 12%);
    height: 100vh;
}

.drawer-left-close {
    width: 80px;
}

.drawer-left-open {
    width: 80px;
}

.drawer-left .block .drawer-title span {
    padding-top: 20px;
    padding-left: 15px;
    padding-right: 15px;
    display: block;
    text-transform: uppercase;
    font-size: 10px;
    font-family: "Roboto", "Helvetica Neue", Arial, sans-serif;
    color: #fff;
    letter-spacing: 1px;
    opacity: .3;
    white-space: nowrap;
}

.light .drawer-left .block .drawer-title span {
    color: #000000;
}

.dark .drawer-left .block .drawer-title span {
    color: #ffffff;
}

.drawer-left .block .drawer-menu {
    white-space: nowrap;
    margin-left: 10px;
    margin-right: 10px;
    transition: all 0.4s;
    font-size: 13px;
}

.drawer-left .block .drawer-menu .list-group {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    padding: .5rem 0;
    margin-bottom: 0;
    margin-top: 0;
}

.drawer-left .block .drawer-menu .list-group a.list-group-item {
    color: white;
    display: block;
    flex-flow: row wrap;
    align-items: center;
    padding: 1rem;
    line-height: 1;
    position: relative;
    transition: all .5s;
    text-decoration: none;
}

.drawer-left .block .drawer-menu .list-group a.list-group-item:hover {
    transition: all .5s;
    cursor: pointer;
}

.dark .drawer-left .block .drawer-menu .list-group a.list-group-item:hover {
    background: #1a2432;
}

.light .drawer-left .block .drawer-menu .list-group a.list-group-item:hover {
    background: #adceef;
}

.drawer-left .block .drawer-menu .list-group a.list-group-item:hover .icon {
    color: #fff;
}

.drawer-left .block .drawer-menu .list-group a.list-group-item.active {
    color: #fff;
    display: block;
    font-size: 14px;
    border-radius: 2px;
    transition: all 0.2s ease-in-out;
    box-shadow: 0 2px 2px 0 rgb(0 0 0 / 14%), 0 3px 1px -2px rgb(0 0 0 / 20%), 0 1px 5px 0 rgb(0 0 0 / 12%);
}

.light .drawer-left .block .drawer-menu .list-group a.list-group-item.active {
    background-color: #64B5F6;
}

.dark .drawer-left .block .drawer-menu .list-group a.list-group-item.active {
    background-color: #00b297;
}

.drawer-left .block .drawer-menu .list-group a.list-group-item svg.icon {
    margin-right: 10px;
}

.drawer-left .block .drawer-menu .list-group a.list-group-item span svg.menu-item-arrow {
    position: absolute;
    right: 10px;
}

.drawer-left .block .drawer-menu .list-group li.list-group-item {
    color: white;
    display: block;
    flex-flow: row wrap;
    align-items: center;
    padding: 1rem;
    line-height: 1;
    position: relative;
    transition: all .5s;
}

.drawer-left .block .drawer-menu .list-group li.list-group-item:hover {
    transition: all .5s;
    cursor: pointer;
}

.light .drawer-left .block .drawer-menu .list-group li.list-group-item:hover {
    background: #adceef;
}

.dark .drawer-left .block .drawer-menu .list-group li.list-group-item:hover {
    background: #1a2432;
}

.light .drawer-left .block .drawer-menu .list-group li.list-group-item:hover svg {
    color: #fff;
    transition: all .5s;
}

.drawer-left .block .drawer-menu .list-group li.list-group-item.active {
    color: #fff;
    display: block;
    font-size: 14px;
    border-radius: 2px;
    transition: all 0.2s ease-in-out;
    box-shadow: 0 2px 2px 0 rgb(0 0 0 / 14%), 0 3px 1px -2px rgb(0 0 0 / 20%), 0 1px 5px 0 rgb(0 0 0 / 12%);
}

.dark .drawer-left .block .drawer-menu .list-group li.list-group-item.active {
    background-color: #00b297!important;
}

.light .drawer-left .block .drawer-menu .list-group li.list-group-item.active {
    background-color: #64B5F6!important;
}

.drawer-left .block .drawer-menu .list-group li.list-group-item svg.icon {
    margin-right: 10px;
}

.drawer-left .block .drawer-menu .list-group li.list-group-item span svg.menu-item-arrow {
    position: absolute;
    right: 10px;
}

.drawer-left .block .drawer-menu .list-group ul.br-menu-sub {
    padding-top: 5px;
    padding-bottom: 5px;
    padding-left: 27px;
    background-color: #1a2432;
    border-bottom-right-radius: 3px;
    border-bottom-left-radius: 3px;
    flex-direction: column !important;
}

.light .drawer-left .block .drawer-menu .list-group ul.br-menu-sub {
    background: #c7c7c7;
}

.drawer-left .block .drawer-menu .list-group ul.br-menu-sub li {
    color: white;
    transition: all 0.4s;
    display: block;
    padding-top: 0;
    padding-left: 5px;
    padding-bottom: 0;
}

.drawer-left .block .drawer-menu .list-group ul.br-menu-sub li a {
    white-space: nowrap;
    position: relative;
    transition: all 0.2s ease-in-out;
    font-size: 13px;
    text-transform: inherit;
    display: block;
    padding: .5rem 1rem;
    text-decoration: none;
    text-overflow: ellipsis;
    overflow-y: clip;
}

.light .drawer-left .block .drawer-menu .list-group ul.br-menu-sub li a {
    color: #fff;
}

.dark .drawer-left .block .drawer-menu .list-group ul.br-menu-sub li a {
    color: #adb5bd;
}

.drawer-left .block .drawer-menu .list-group ul.br-menu-sub li a:before {
    content: '';
    position: absolute;
    top: 50%;
    left: -5px;
    margin-top: -.5px;
    width: 5px;
    height: 1px;
    opacity: .5;
    transition: all 0.2s ease-in-out;
}

.light .drawer-left .block .drawer-menu .list-group ul.br-menu-sub li a:before {
    background-color: #fff;
}

.dark .drawer-left .block .drawer-menu .list-group ul.br-menu-sub li a:before {
    background-color: #fff;
}

.light .drawer-left .block .drawer-menu .list-group ul.br-menu-sub li a.active {
    color: #008dff;
}

.dark .drawer-left .block .drawer-menu .list-group ul.br-menu-sub li a.active {
    color: #17a2b8;
}

.drawer-left .block .drawer-menu .list-group ul.br-menu-sub li a.active:before {
    width: 20px;
    left: -15px;
    opacity: 1;
    transition: all 0.2s ease-in-out;
    height: 1px;
    margin-top: -.5px;
    content: '';
    position: absolute;
    top: 50%;
}

.light .drawer-left .block .drawer-menu .list-group ul.br-menu-sub li a.active:before {
    background-color: #008dff;
}

.dark .drawer-left .block .drawer-menu .list-group ul.br-menu-sub li a.active:before {
     background-color: #17A2B8;
 }

.drawer-left .block .drawer-menu .list-group ul.br-menu-sub li a:hover:before {
    width: 20px;
    left: -15px;
    opacity: 1;
    transition: all 0.2s ease-in-out;
    height: 1px;
    margin-top: -.5px;
    content: '';
    position: absolute;
    top: 50%;
}

.dark .drawer-left .block .drawer-menu .list-group ul.br-menu-sub li a:hover:before {
    background-color: #17A2B8;
}

.light .drawer-left .block .drawer-menu .list-group ul.br-menu-sub li a:hover:before {
    background-color: #008dff;
}

.dark .drawer-left .block .drawer-menu .list-group ul.br-menu-sub li a:hover {
    color: #17a2b8;
}

.light .drawer-left .block .drawer-menu .list-group ul.br-menu-sub li a:hover {
    color: #008dff;
}

.drawer-left .block .drawer-menu .list-group div.icon-children {
    position: absolute;
    right: 0;
    top: 50%;
    transform: translate(0%, -50%);
}

.drawer-left .block .drawer-menu .list-group div.icon-children svg {
    margin-right: 5px;
}

.drawer-left.close .drawer-title span {
    font-size: 7px!important;
}

.drawer-left .block .drawer-menu .list-group div.icon-children {
    opacity: 1;
}

.drawer-left.close .block .drawer-menu .list-group div.icon-children {
    opacity: 0;
}

.drawer-left.close .drawer-menu {
    transition: all .5s;
}

.drawer-left.close .drawer-menu .list-group-item {
    padding-bottom: 0!important;
}

.drawer-left.close .drawer-menu .list-group-item .disable-link {
    pointer-events: none;
}

.drawer-left.close .drawer-menu .list-group-item span {
    opacity: 0;
}

.drawer-left.close .drawer-menu .list-group-item .icon {
    margin-right: 0!important;
    display: block;
    text-align: center;
    transition: all .5s;
    font-size: 18px;
    padding-left: 5px;
}

.light .drawer-left.close .drawer-menu .list-group-item .icon {
    color: #92a0bc;
}

.light .drawer-left.close .drawer-menu .list-group-item.active .icon {
    color: #fff;
}

.light .drawer-left.open .list-group-item span {
    color: #717273;
}

.light .drawer-left.open .list-group-item:hover span {
    color: #fff;
}

.light .drawer-left.open .list-group-item .icon {
    color: #717273;
}

.light .drawer-left.open .list-group-item .icon-children svg {
    color: #717273;
}

.light .drawer-left.open .list-group-item.active span {
    color: #fff;
}

.light .drawer-left.open .list-group-item.active .icon {
    color: #fff;
}

.light .drawer-left.open .list-group-item.active .icon-children svg {
    color: #fff;
}

@keyframes fadeInFromNone {
    0% {
        display: block;
    }
    100% {
        display: none;
    }
}
