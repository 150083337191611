.section-content .content-page span.label-value {
    color: black;
    font-weight: bold;
}

.section-content .content-page .form-btn {
    margin-top: 25px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    flex-wrap: nowrap;
}

.light .section-content .content-page .form-btn span {
    color: white;
}

.section-content .table-items {
    margin-top: 25px;
}

.section-content .table-items .table.empty table {
    min-height: 350px;
    position: relative;
}

.section-page-list .content-page .color-select-content {
    padding: 5px 5px 8px 0;
    font-size: 14px;
    color: #fff9;
}

.section-page-list .content-page .errors {
    margin-top: 15px;
    padding: 10px;
    background: #334057;
    border-radius: 15px;
    border: 1px solid #ef9a9a;
}

.section-page-list .content-page .errors .error-item {
    color: #ef9a9a;
}
