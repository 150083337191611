.overlay-panel-bell .notification .notification-empty {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    margin-top: -10px;
}

.overlay-panel-bell .notification .notification-empty div svg {
    width: 90px;
    height: 90px;
}

.light .overlay-panel-bell .notification .notification-empty div svg {
    color: #b6b6b6;
}

.dark .overlay-panel-bell .notification .notification-empty div svg {
    color: #304562;
}
