.form-split-btn button.p-button.p-button.p-component.p-splitbutton-menubutton.p-button-icon-only {
    border-bottom-left-radius: 0;
    border-top-left-radius: 0;
}

.form-split-btn button {
    color: white;
}

.light .form-split-btn .p-menu.p-menu-overlay {
    background: #eae9e9;
    border-color: #fff;
    padding: 0;
}

.light .form-split-btn .p-menu.p-menu-overlay a {
    color: black;
}

.light .form-split-btn .p-menu.p-menu-overlay a span {
    color: black;
}

.light .form-split-btn .p-menu.p-menu-overlay li:hover {
    background: white;
}

.light .form-split-btn .p-menu.p-menu-overlay li:hover svg {
    color: #64B5F6;
}

.light .form-split-btn .p-menu.p-menu-overlay li:hover span {
    color: #64B5F6;
}
