.section-page-list .splitter-content {
    padding-top: 15px;
}

.section-page-list .splitter-content .splitter .editor {
    width: 100%;
}

.section-page-list .splitter-content .splitter .result {
    padding: 10px;
}

.section-page-list .splitter-content .splitter .result a {

}
