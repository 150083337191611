.upload-image {
    position: relative;
}

.upload-image .upload-content {
    position: relative;
    width: 250px;
    height: 250px;
    border-radius: 50%;
    padding: 15px;
    background: #4c6088;
    border: 1px solid #4c6088;
    transition: all .3s;
    box-shadow: 0 2px 2px 0 rgb(0 0 0 / 14%), 0 3px 1px -2px rgb(0 0 0 / 20%), 0 1px 5px 0 rgb(0 0 0 / 12%);
}

.upload-image .upload-content:hover {
    cursor: pointer;
    transition: all .3s;
    border: 1px solid #89a6ef;
}

.upload-image .upload-content:hover svg {
    color: #89a6ef;
    transition: all .3s;
    transform: rotate(360deg);
}

.upload-image .upload-content svg {
    transition: all .3s;
    transform: rotate(0deg);
}

.upload-image .upload-content:hover .empty-item span {
    color: #89a6ef;
    transition: all .3s;
}

.upload-image .upload-content .empty-item span {
    transition: all .3s;
}

.upload-image .upload-content img {
    position: absolute;
    width: 250px;
    height: 250px;
    border-radius: 50%;
    top: 0;
    left: 0;
    object-fit: cover;
}

.upload-image .upload-content .empty-content-image {
    text-align: center;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
}

.upload-image .controls {
    text-align: center;
    margin-top: 15px;
}
