.table {
    width: 100%;
}

.light .table.p-datatable .p-datatable-thead > tr > th {
    background: #fff;
    color: #000;
}

.light .table.p-datatable .p-datatable-tbody > tr {
    background: #fff;
}

.table-items .table .control {
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.table-items .table .control button {
    padding: 5px;
    margin: 5px;
}

.table-items .table .p-datatable-tbody tr:hover {
    background: #28344a;
    cursor: pointer;
    transition: all .4s;
}

.table-items .table .image {
    color: #fff;
    border-radius: 50%;
}

.table-items .table .control button.btn-edit {
    background: #baa462;
    border-color: #baa462;
}

.table-items .table .control button.btn-edit span {
    color: #543f00;
}

.table-items .table .control button.btn-delete {
    background: #ef9a9a;
    border-color: #ef9a9a;
}

.table-items .paginator {
    position: relative;
}

.table-items .paginator .mask-paginator-disable {
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 2;
}

.table-items .control button {
    color: #fff;
}

.light .table-items .table .image {
    color: #fff;
    border-radius: 50%;
    background: #c0c0c0;
    box-shadow: 0 2px 2px 0 rgb(0 0 0 / 14%), 0 3px 1px -2px rgb(0 0 0 / 20%), 0 1px 5px 0 rgb(0 0 0 / 12%);
}

.table-items .table .tag-id {
    text-overflow: ellipsis;
    overflow: hidden;
    display: flow-root;
    width: 70px;
    height: 25px;
    color: #fff;
    background: #4a93b4;
}

.table-items .table .label-tag {
    color: #fff;
}

.table-items .table .label-tag.label-tag-count {
    width: 80px;
}

.light .table-items .table .label {
    color: #000;
}

.light .table.p-datatable .p-datatable-tbody > tr:hover {
    background: #eae9e9;
}

.light .table-items .paginator-content .paginator {
    background: #fff;
    border: 0;
}

.light .table-items .paginator-content .paginator button {
    color: #000;
}
